import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wafer bonding and aligning tools`}</h4>
    <p>{`Wafer bonders and aligners join silicon wafers. In this visualization, these tools are included as an input to producing wafers, but they may also be used later in the production process, after chips are fabricated in the wafers. Austria, Germany, and the United States produce this equipment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      